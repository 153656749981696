import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { ADMIN_TOKEN_NSP } from "../constants";
import { transformTourPackage } from "./dataCustoms/tourPackages";

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL + "/admin",
  (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem(ADMIN_TOKEN_NSP);
    if (token) {
      options.headers.set("Authorization", "Bearer " + token);
    }
    console.log("url", url);
    console.log("options", options);
    return fetchUtils.fetchJson(url, options);
  }
);

const myProvider = {
  ...dataProvider,
  update: async (resource, params) => {
    if (resource === "tour-packages") {
      params = await transformTourPackage({ params });
    }
    console.log("resource_update", resource);
    console.log("params", params);
    return dataProvider.update(resource, params);
  },
  create: async (resource, params) => {
    if (resource === "tour-packages") {
      params = await transformTourPackage({ params });
    }
    console.log("resource_create", resource);
    console.log("params", params);
    return dataProvider.create(resource, params);
  },
};

export default myProvider;
