import { ADMIN_TOKEN_NSP } from "../constants";

const authProvider = {
  // authentication
  login: async ({ username: email, password }) => {
    return fetch(process.env.REACT_APP_API_URL + "/admin/login", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => localStorage.setItem(ADMIN_TOKEN_NSP, auth.token))
      .catch(() => {
        throw new Error("Network error");
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(ADMIN_TOKEN_NSP);
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem(ADMIN_TOKEN_NSP)
      ? Promise.resolve()
      : Promise.reject(),
  logout: () => {
    localStorage.removeItem(ADMIN_TOKEN_NSP);
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve({ name: "Admin" }),
  // authorization
  getPermissions: (params) => Promise.resolve(),
};

export default authProvider;
