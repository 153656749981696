import { Admin, ListGuesser, Resource } from "react-admin";
import authProvider from "./raProviders/authProvider";
import dataProvider from "./raProviders/dataProvider";
import { i18nProvider } from "./raProviders/i18nProvider";
import {
  TourPackageCreate,
  TourPackageEdit,
  TourPackageList,
} from "./components/tourPackages";
import { myTheme } from "./theme";

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      theme={myTheme}
    >
      <Resource
        name="tour-packages"
        list={TourPackageList}
        create={TourPackageCreate}
        edit={TourPackageEdit}
        options={{ label: "Paquetes" }}
      />
      <Resource
        name="customers"
        list={ListGuesser}
        options={{ label: "Clientes" }}
      />
      <Resource
        name="orders"
        list={ListGuesser}
        options={{ label: "Pedidos" }}
      />
      <Resource
        name="config"
        list={ListGuesser}
        options={{ label: "Configuración" }}
      />
    </Admin>
  );
}

export default App;
