import { defaultTheme } from "react-admin";
import merge from "lodash/merge";

const htTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: "#caff80",
      main: "#64aa00",
      dark: "#4a8000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e19c6b",
      main: "#a35821",
      dark: "#582f00",
      contrastText: "#fff",
    },
  },
});

const lmTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: "#40738f",
      main: "#274758",
      dark: "#274758",
      contrastText: "#fff",
    },
    secondary: {
      light: "#40738f",
      main: "#274758",
      dark: "#274758",
      contrastText: "#fff",
    },
  },
});

const shtTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: "#67C682",
      main: "#67C682",
      dark: "#67C682",
      contrastText: "#fff",
    },
    secondary: {
      light: "#67C682",
      main: "#67C682",
      dark: "#67C682",
      contrastText: "#fff",
    },
  },
});

const brand = process.env.REACT_APP_BRAND || "ht";

export const myTheme =
  brand === "lm" ? lmTheme : brand === "sht" ? shtTheme : htTheme;
