import { useEffect } from "react";
import { memoize } from "lodash";
import pDebounce from "p-debounce";
import RichTextInput from "ra-input-rich-text";
import { Typography, useMediaQuery } from "@material-ui/core";
import {
  List,
  Datagrid,
  Edit,
  Create,
  TextField,
  TextInput,
  NumberField,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  ImageField,
  ImageInput,
  FormDataConsumer,
  SimpleList,
  required,
  TabbedForm,
  FormTab,
  FunctionField,
  DateTimeInput,
  BooleanInput,
  useRecordContext,
  SelectArrayInput,
} from "react-admin";
import { NoInput } from "./shared/NoInput";

const listItemsIcons = [
  { id: 1, name: "Aire Acondicionado" },
  { id: 2, name: "Piscina" },
  { id: 3, name: "Zona Wifi" },
  { id: 4, name: "Areas Verdes" },
  { id: 5, name: "Tv Smart" },
  { id: 6, name: "Restaurante" },
];

export const TourPackageList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      {...props}
      exporter={false}
      title="Lista de Paquetes"
      sort={{ field: "relevance", order: "DESC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) =>
            `${record.capacity.min}-${record.capacity.max} personas - ${record.duration} noches`
          }
          tertiaryText={(record) => `S/.${record.price}`}
        />
      ) : (
        <Datagrid rowClick="edit">
          <FunctionField
            source="relevance"
            label="Relevancia"
            render={(record) => record.relevance || 0}
          />
          <TextField source="name" label="Nombre" />
          <NumberField source="capacity.min" label="Personas mín" />
          <NumberField source="capacity.max" label="Personas máx" />
          <NumberField source="duration" label="Noches" />
          <NumberField source="price" label="Precio" />
          <FunctionField
            label="Oferta"
            render={(record) => {
              if (record.offer?.enabled) {
                if (new Date(record.offer.from) > new Date()) {
                  return "Programado";
                } else if (new Date(record.offer.to) < new Date()) {
                  return "Expirado";
                } else {
                  return "En curso";
                }
              }
              return "Desactivado";
            }}
          />
          <FunctionField
            label="Iconos"
            render={(record) => {
              const data = [];
              record.listIcons.map((x) => {
                data.push(listItemsIcons.find((y) => y.id == x).name + " ");
              });
              return data;
            }}
          />
          <NumberField source="offer.price" label="Precio oferta" />
        </Datagrid>
      )}
    </List>
  );
};

const TourPackageTitle = ({ record }) => {
  return <span>Paquete: {record ? `${record.name}` : ""}</span>;
};

const fetchBySlug = memoize(async (slug) => {
  const path = "/customer/tour-packages/" + slug;
  return fetch(process.env.REACT_APP_API_URL + path).then((response) =>
    response.json()
  );
});

const slugAvailable = pDebounce(async (value, values) => {
  const data = await fetchBySlug(value);
  if (values.id && data?.id === values.id) return;
  if (data.id) return "Este slug ya está siendo usado";
}, 300);

const TourPackageForm = ({ edit, ...props }) => {
  useEffect(() => {
    fetchBySlug.cache.clear();
  }, []);

  const record = useRecordContext();
  console.log("edit", edit);
  console.log("record_TourPackageForm", record);
  return (
    <TabbedForm {...props}>
      <FormTab label="General">
        {edit && <TextInput disabled source="id" />}
        <TextInput
          source="slug"
          label="Slug"
          onKeyPress={(e) => !/[a-z\d-]/.test(e.key) && e.preventDefault()}
          validate={[required(), slugAvailable]}
        />
        <SelectArrayInput
          label="Iconos"
          source="listIcons"
          choices={listItemsIcons}
        />

        <NumberInput
          source="relevance"
          label="Relevancia"
          defaultValue={record.relevance}
          validate={[required()]}
          step={1}
        />
        <TextInput source="name" label="Nombre" validate={required()} />
        <NumberInput
          source="capacity.min"
          label="Capacidad mínima"
          validate={required()}
        />
        <NumberInput
          source="capacity.max"
          label="Capacidad máxima"
          validate={required()}
        />
        <NumberInput
          source="duration"
          label="Cantidad de noches"
          validate={required()}
        />
        <NumberInput source="price" label="Precio" validate={required()} />

        <NoInput>
          <Typography variant="h5" style={{ marginTop: 16 }}>
            Oferta
          </Typography>
        </NoInput>
        <BooleanInput
          source="offer.enabled"
          label="Activo"
          defaultValue={false}
        />
        <NumberInput
          source="offer.price"
          label="Precio oferta"
          defaultValue={record.price}
        />
        <DateTimeInput
          source="offer.from"
          label="Válido desde"
          defaultValue={new Date()}
        />
        <DateTimeInput
          source="offer.to"
          label="Válido hasta"
          defaultValue={new Date()}
        />
        <BooleanInput
          source="PackSelling"
          label="Mas Vendidos"
          defaultValue={false}
        />
      </FormTab>
      <FormTab label="Detalles">
        <ArrayInput
          source="details"
          label=""
          defaultValue={edit ? undefined : [{ title: "", description: "" }]}
        >
          <SimpleFormIterator>
            <TextInput source="title" label="Título" validate={required()} />
            <RichTextInput
              source="description"
              label="Descripción"
              validation={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Imágenes">
        <ArrayInput
          source="images"
          defaultValue={edit ? undefined : [""]}
          label=""
        >
          <SimpleFormIterator>
            <ImageInput
              format={(v) => (!v || typeof v === "string" ? { src: v } : v)}
              label="Fotografía"
              accept="image/*"
              validate={required()}
              defaultValue={""}
            >
              <ImageField source="src" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Vídeos">
        <ArrayInput
          source="videos"
          label=""
          defaultValue={edit ? undefined : [""]}
        >
          <SimpleFormIterator>
            <TextInput
              label="Youtube Video ID"
              validate={required()}
              defaultValue={""}
            />
            <FormDataConsumer>
              {({ scopedFormData, getSource }) =>
                scopedFormData?.length === 11 && (
                  <div key={getSource("")}>
                    <iframe
                      title={scopedFormData}
                      style={{ maxWidth: "100%" }}
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${scopedFormData}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
};

export const TourPackageEdit = (props) => (
  <Edit title={<TourPackageTitle />} {...props}>
    <TourPackageForm edit />
  </Edit>
);

export const TourPackageCreate = (props) => (
  <Create title="Crear un paquete" {...props}>
    <TourPackageForm />
  </Create>
);
