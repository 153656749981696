import { convertFileToBase64 } from "../../utils/fileToBase64";

export const transformTourPackage = ({ params }) => {
  return Promise.all(
    params.data.images.map(
      (image) =>
        new Promise((resolve) => {
          if (!(image.rawFile instanceof File)) resolve(image);
          else convertFileToBase64(image.rawFile).then(resolve);
        })
    )
  ).then((images) => ({
    ...params,
    data: {
      ...params.data,
      images,
    },
  }));
};
